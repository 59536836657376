export const statusOptions = [
  {
    label: "active",
    value: "Active",
  },
  {
    label: "inactive",
    value: "Inactive",
  },
];

export const sessionStatusOptions = [
  {
    label: "current",
    value: "Current",
  },
  {
    label: "closed",
    value: "Closed",
  },
];

export const fullMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shortMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

type WeekStartDayOption = {
  name: string;
  value: number;
};
export const emptyArray = [...new Array(3)];

export const weekStartDayOptions: WeekStartDayOption[] = [
  {
    name: "saturday",
    value: 6,
  },
  {
    name: "sunday",
    value: 0,
  },
  {
    name: "monday",
    value: 1,
  },
  // {
  //   name: "tuesday",
  //   value: 2,
  // },
  // {
  //   name: "wednesday",
  //   value: 3,
  // },
  // {
  //   name: "thursday",
  //   value: 4,
  // },
  // {
  //   name: "friday",
  //   value: 5,
  // },
];

export const weekDayOptions: WeekStartDayOption[] = [
  {
    name: "saturday",
    value: 6,
  },
  {
    name: "sunday",
    value: 0,
  },
  {
    name: "monday",
    value: 1,
  },
  {
    name: "tuesday",
    value: 2,
  },
  {
    name: "wednesday",
    value: 3,
  },
  {
    name: "thursday",
    value: 4,
  },
  {
    name: "friday",
    value: 5,
  },
];

export const fileSizeOptions = [
  { name: "1 MB", value: "1" },
  { name: "2 MB", value: "2" },
  { name: "3 MB", value: "3" },
  { name: "4 MB", value: "4" },
  { name: "5 MB", value: "5" },
];
export const attendanceTypes = [
  {
    name: "Rysenova Web",
    value: "Rysenova Web",
  },
  {
    name: "Rysenova App",
    value: "Rysenova App",
  },
  {
    name: "Attendance Device",
    value: "Attendance Device",
  },
];
export const statusType = [
  {
    title: "all",
    value: "All",
  },
  {
    title: "active",
    value: "Active",
  },

  {
    title: "inactive",
    value: "Inactive",
  },
  {
    title: "pending",
    value: "Pending",
  },

  {
    title: "suspended",
    value: "Suspended",
  },

  {
    title: "archived",
    value: "Archived",
  },
];
export const filteredStatusType = [
  {
    title: "all",
    value: "All",
  },
  {
    title: "active",
    value: "Active",
  },

  {
    title: "inactive",
    value: "Inactive",
  },
];

export const daysOfWeekArr = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const limitOption = [
  {
    id: "1",
    name: "limit",
    value: "10",
    label: "10_items",
  },
  {
    id: "2",
    name: "limit",
    value: "20",
    label: "20_items",
  },
  {
    id: "3",
    name: "limit",
    value: "30",
    label: "30_items",
  },
  {
    id: "4",
    name: "limit",
    value: "40",
    label: "40_items",
  },
];
